import axios from 'axios';

const state = {
    
};

const getters = {
};

const actions = {
    async getPendingApplication(state) {// get remita payment status
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getPendingApplicationUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, { id : state.rootState.user.studentId }, { headers: header});// make api call
        return response.data;
    },
    async updateStudentData(state, data) {// get remita payment status
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.updateStudentDataUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
    async submitStudentIndexingApplication(state, id) {// get remita payment status
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.submitStudentIndexingApplicationUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, { id : id }, { headers: header});// make api call
        return response.data;
    },
    async getStudentApplications(state, data) {// get remita payment status
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getStudentApplicationsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
    async getPaginatedApplicationItems(state, url) {// get paginated applications
        const authorizationToken = state.rootState.url.authorizationToken;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, {}, { headers: header});// make api call
        return response.data;
    },
    async getApplication(state, id) {// get remita payment status
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getApplicationUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, { id: id }, { headers: header});// make api call
        return response.data;
    },
    async getStudentIndexingDetails(state) {// get Student Indexing Details
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getStudentIndexingDetailsUrl;
        const studentId = state.rootState.user.studentId;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, { id: studentId }, { headers: header});// make api call
        return response.data;
    },
    
    // async getCpdLetters(state) {// get remita payment status
    //     const authorizationToken = state.rootState.url.authorizationToken;
    //     const url = state.rootState.url.getCpdLettersUrl;
    //     const header = {Authorization: `Bearer ${authorizationToken}`};
    //     let response = await axios.post(url, { id : state.rootState.user.cpdId }, { headers: header});// make api call
    //     return response.data;
    // }
};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations
}