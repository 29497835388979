<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
      :background-color="sidebarBackground"
      short-title="MDCN"
      title="MDCN"
    >
      <template slot="links">
        <sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'ni ni-tv-2 text-primary',
            path: '/dashboard' 
          }"
        />

        <sidebar-item :link="{name: 'My Account', icon: 'ni ni-single-02 text-yellow', path: '/profile'}"/>
        <sidebar-item :link="{name: 'Change Password', icon: 'ni ni-settings-gear-65 text-red', path: '/change/password'}"/>
        <sidebar-item :link="{name: 'My Applications', icon: 'ni ni-planet text-blue', path: '/applications'}"/>
        <sidebar-item :link="{name: 'Apply For Clearance', icon: 'ni ni-credit-card text-green', path: '/indexing/apply'}"/>        
        <sidebar-item :link="{name: 'My Payments', icon: 'ni ni-credit-card text-red', path: '/payments'}"/>
        <sidebar-item :link="{name: 'Insurance Payments', icon: 'ni ni-bell-55 text-yellow', path: '/insurance/payments'}"/>
        <!-- <sidebar-item :link="{name: 'My Clearance', icon: 'ni ni-bullet-list-67 text-blue', path: '/my/clearance'}"/> -->
        <sidebar-item :link="{name: 'Requirements', icon: 'ni ni-folder-17 text-info', path: '/requirements-policy'}"/>
        <sidebar-item :link="{name: 'Logout', icon: 'ni ni-user-run text-pink', path: '/logout'}"/>

      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view :key="$route.fullPath"></router-view>
        </fade-transition>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
  import DashboardNavbar from './DashboardNavbar.vue';
  import ContentFooter from './ContentFooter.vue';
  import { FadeTransition } from 'vue2-transitions';

  export default {
    components: {
      DashboardNavbar,
      ContentFooter,
      FadeTransition
    },
    data() {
      return {
        sidebarBackground: 'vue' //vue|blue|orange|green|red|primary
      };
    },
    methods: {
      toggleSidebar() {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false);
        }
      }
    }
  };
</script>
<style lang="scss">
</style>
