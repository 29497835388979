import axios from 'axios';

const state = {

};

const getters = {
};

const actions = {
    async getVerificationPayment(state) {// get remita payment status
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getVerificationPaymentUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, { id : state.rootState.user.studentId}, { headers: header});// make api call
        return response.data;
    },
    async generateVerificationRrr(state, data) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.generateVerificationRrrUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
    async saveApplicationTransaction(state, orderId) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.saveApplicationTransactionUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, { order_id : orderId, id : state.rootState.user.studentId }, { headers: header});// make api call
        return response.data;
    },
    async applicationRrrStatus(state, rrr) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.applicationRrrStatusUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, { rrr : rrr, id : state.rootState.user.studentId }, { headers: header});// make api call
        return response.data;
    },
    async getRemitaPaymentStatus(state, data) {// get remita payment status
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getRemitaPaymentStatusUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
    async getStudentPayments(state, limit) {// get user Appointments
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getStudentPaymentsUrl;
        const studentId = state.rootState.user.studentId;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, { id: studentId, limit : limit }, { headers: header});// make api call
        return response.data;
    },
    async getStudentInsuranceRecords(state, limit) {// get remita payment status
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getStudentInsuranceRecordsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, { id : state.rootState.user.studentId, limit : limit }, { headers: header});// make api call
        return response.data;
    },
    async createStudentInsurance(state, data) {// get remita payment status
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.createStudentInsuranceUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
    async updateInsuranceRef(state, data) {// get remita payment status
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.updateInsuranceRefUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        data.id = state.rootState.user.studentId;
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
    async approvedInsurancePayment(state, data) {// get remita payment status
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.approvedInsurancePaymentUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations
}