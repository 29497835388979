import axios from 'axios';

const perPage = 20;

const state = {
    accessToken: localStorage.getItem('access_token') || null,
    studentId: localStorage.getItem('student_id') || null,
    student: {},
    lock: false,
    uploadProgress: null,
    perPage: perPage,
    notifications: {},
    locations: {}
};

const getters = {
    isLoggedIn: state => state.accessToken !== null && state.studentId !== null ? true : false,
    student: state => state.student,
    lock: state => state.student && (state.student.profile_lock == 'yes') ? true : false,
    notifications: state => state.notifications,
    locations: state => state.locations
};

const actions = {
    async createSignup(state, data) {// signup
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.createSignupUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data, { headers: header });// make api call
        return response.data;
    },
    destroyAccessToken(state) {// destroy local storage
        localStorage.removeItem('access_token');// remove token
        localStorage.removeItem('student_id');// remove student id
        localStorage.removeItem('time_out');// remove time out
        return state.accessToken = null;
    },
    storeAndLoginUser(state, data) {//set token and login user
        let hours = 2;
        localStorage.setItem('access_token', data.access_token);// set access token
        localStorage.setItem('student_id', data.student_id);// set student id
        localStorage.setItem('time_out', new Date().getTime() + (hours * 60 * 60 * 1000));// set timeout
    },
    async studentLogin(state, data) {// fetch locations (states, lgas, and countries)
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.studentLoginUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data, { headers: header });// make api call
        return response.data;
    },
    async fetchStudentProfile(state) {// fetch user profile
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.studentProfileUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, {'id' : state.state.studentId},
                { headers: header });// make api call
        state.commit('setStudent', response.data.student);
        return response.data.student;
    },
    updatePhotoName({ commit }, url) {// update photo name
        commit('setUpdatePhotoName', url);// set update photo function
    },
    async uploadUserPhoto(state, data) {// upload user documents
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.uploadStudentPhotoUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, {
            headers: header,
            onUploadProgress: uploadEvent => {
                state.state.uploadProgress = Math.round(uploadEvent.loaded / uploadEvent.total * 100);
            }
        });// make api call
        return response.data;
    },
    async uploadStudentDocuments(state, data) {// get remita payment status
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.uploadStudentDocumentsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, {
            headers: header,
            onUploadProgress: uploadEvent => {
                state.state.uploadProgress = Math.round(uploadEvent.loaded / uploadEvent.total * 100);
            }
        });// make api call
        return response.data;
    },
    async changePassword(state, data) {// fetch locations (states, lgas, and countries)
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.changePasswordUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url, data, { headers: header });// make api call
        return response.data;
    },
    async getLocations(state) {// fetch locations (states, lgas, and countries)
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.locationsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        const response = await axios.post(url,{}, { headers: header });// make api call
        state.commit('setLocations', response.data);
        return response.data;
    },
    
    // async getCpdNotifications(state, limit) {// fetch user profile
    //     const authorizationToken = state.rootState.url.authorizationToken;
    //     const url = state.rootState.url.getCpdNotificationsUrl;
    //     const header = {Authorization: `Bearer ${authorizationToken}`};
    //     const response = await axios.post(url, { id : state.state.studentId, limit : limit }, { headers: header });// make api call
    //     state.commit('setCpdNotifications', response.data);
    //     return response.data;
    // },
    // async getCpdNotification(state, slug) {// fetch user profile
    //     const authorizationToken = state.rootState.url.authorizationToken;
    //     const url = state.rootState.url.getCpdNotificationsUrl;
    //     const header = {Authorization: `Bearer ${authorizationToken}`};
    //     const response = await axios.post(url, { id : state.state.studentId, slug : slug }, { headers: header });// make api call
    //     return response.data;
    // },
    // async deleteCpdNotification(state, id) {// fetch user profile
    //     const authorizationToken = state.rootState.url.authorizationToken;
    //     const url = state.rootState.url.deleteCpdNotificationUrl;
    //     const header = {Authorization: `Bearer ${authorizationToken}`};
    //     const response = await axios.post(url, { id : id }, { headers: header });// make api call
    //     return response.data;
    // },    
};

const mutations = {
    setStudent: (state, student) => (state.student = student),// set user profile
    setUpdatePhotoName: (state, url) => (state.student.photo_url = url),// set user basic profile only
    setLocations: (state, data) => (state.locations = data),// set locations (states, lgas, and countries)

    // setCpdNotifications(state, data) {// set user notifications
    //     state.notifications.records = data.notifications.data;
    //     state.notifications.total = data.total;
    // }    
};

export default {
    state,
    getters,
    actions,
    mutations
}