const rootUrl = 'https://test.api.portal.mdcn.gov.ng';
const portalUrl = 'https://test.student.portal.mdcn.gov.ng';
const remitaVerificationCallBackUrl = 'https://test.student.portal.mdcn.gov.ng/indexing/callback';
// user module Urls
const createSignupUrl = rootUrl+'/student/register';
const studentLoginUrl = rootUrl+'/student/login';
const studentProfileUrl = rootUrl+'/get/student/profile';
const uploadStudentPhotoUrl = rootUrl+'/upload/student/photo';
const changePasswordUrl = rootUrl+'/change/student/password';
const locationsUrl = rootUrl+'/get-locations';

// const getCpdNotificationsUrl = rootUrl+'/get-cpd-notifications';
// const deleteCpdNotificationUrl = rootUrl+'/delete-cpd-notification';

// Parameter module Urls
const getSiteParameterUrl = rootUrl+'/get-parameter-value';
// Payment Urls
const getVerificationPaymentUrl = rootUrl+'/student/indexing/payment';
const saveApplicationTransactionUrl = rootUrl+'/save/student/application/transaction';
const generateVerificationRrrUrl = rootUrl+'/generate-assessment-rrr';
const applicationRrrStatusUrl = rootUrl+'/student/indexing/rrr';
const getRemitaPaymentStatusUrl = rootUrl+'/get-rrr-payment-status';
const getStudentPaymentsUrl = rootUrl+'/get/student/payments';
const getStudentInsuranceRecordsUrl = rootUrl+'/get/student/insurance';
const createStudentInsuranceUrl = rootUrl+'/create/student/insurance';
const updateInsuranceRefUrl = rootUrl+'/update/student/insurance';
const approvedInsurancePaymentUrl = rootUrl+'/approve/student/insurance';
// Application Urls 
const getPendingApplicationUrl = rootUrl+'/student/pending/indexing/application';
const updateStudentDataUrl = rootUrl+'/update/student/data';
const uploadStudentDocumentsUrl = rootUrl+'/upload/student/documents';
const submitStudentIndexingApplicationUrl = rootUrl+'/submit/student/indexing/application';
const getStudentApplicationsUrl = rootUrl+'/get/student/applications';
const getApplicationUrl = rootUrl+'/get/student/application';
const getStudentIndexingDetailsUrl = rootUrl+'/get/student/indexing/info';

// const getCpdLettersUrl = rootUrl+'/get-cpd-letters';

// other Urls
const loadingBladeUrl = rootUrl+'/storage/images/loading.gif';
const logoutUserUrl = rootUrl+'/logout';
const resetPasswordUrl = rootUrl+'/reset/student/password';
const blankImageUrl = rootUrl+'/storage/images/blank-photo.png';
const perPage = 20;
const recaptchaKey = '6LfIxSUaAAAAAGt9xeD2zcDuZWwcK9lv2Pe6Yu4P';
const websiteUrl = 'https://mdcn.gov.ng';
const printReceiptUrl = rootUrl+'/print/student/receipt';
const certificateImageUrl = rootUrl+'/storage/images/certificate.png';
const completeImageUrl = rootUrl+'/storage/images/complete.jpeg';
// Authorization Key
const authorizationToken = '2TcXrFhPN7qIRCEobdeZcYkqZgiSRRmYOwvjce1';
// Remita Urls & Keys
const remitaPaymentUrl ='https://login.remita.net/remita/ecomm/init.reg';
const remitaMerchantId = '632028472';
const remitaApiKey = '361049';
// paystack keys
const paystackkey = 'pk_test_937bf656f7775f703c13200480c1e8fed030ddce';
const subaccount = 'ACCT_ip7le6f62h247ed';

const state = {
    approvedInsurancePaymentUrl: approvedInsurancePaymentUrl,
    updateInsuranceRefUrl: updateInsuranceRefUrl,
    createStudentInsuranceUrl: createStudentInsuranceUrl,
    getStudentIndexingDetailsUrl: getStudentIndexingDetailsUrl,
    getStudentInsuranceRecordsUrl: getStudentInsuranceRecordsUrl,
    applicationRrrStatusUrl: applicationRrrStatusUrl,
    saveApplicationTransactionUrl: saveApplicationTransactionUrl,
    getVerificationPaymentUrl: getVerificationPaymentUrl,
    getRemitaPaymentStatusUrl: getRemitaPaymentStatusUrl,
    // getCpdLettersUrl: getCpdLettersUrl,
    uploadStudentDocumentsUrl: uploadStudentDocumentsUrl,
    locationsUrl: locationsUrl,
    updateStudentDataUrl: updateStudentDataUrl,
    createSignupUrl: createSignupUrl,
    getSiteParameterUrl: getSiteParameterUrl,
    studentLoginUrl: studentLoginUrl,
    studentProfileUrl: studentProfileUrl,
    generateVerificationRrrUrl: generateVerificationRrrUrl,
    getPendingApplicationUrl: getPendingApplicationUrl,
    submitStudentIndexingApplicationUrl: submitStudentIndexingApplicationUrl,
    // getCpdNotificationsUrl: getCpdNotificationsUrl,
    uploadStudentPhotoUrl: uploadStudentPhotoUrl,
    getStudentApplicationsUrl: getStudentApplicationsUrl,
    getApplicationUrl: getApplicationUrl,
    changePasswordUrl: changePasswordUrl,
    getStudentPaymentsUrl: getStudentPaymentsUrl,
    // deleteCpdNotificationUrl: deleteCpdNotificationUrl,
    cpdId: localStorage.getItem('cpd_id') || null,
    authorizationToken: authorizationToken,
    perPage: perPage,
    remitaApiKey: remitaApiKey,
    remitaPaymentUrl: remitaPaymentUrl,
    remitaMerchantId: remitaMerchantId,
    rootUrl: rootUrl,
    portalUrl: portalUrl
};

const getters = {
    loadingBladeUrl: () => loadingBladeUrl,
    getLogoutUserUrl: () => logoutUserUrl,
    remitaVerificationCallBackUrl: () => remitaVerificationCallBackUrl,
    recaptchaKey: () => recaptchaKey,
    blankImageUrl: () => blankImageUrl,
    getResetPasswordUrl: () => resetPasswordUrl,
    cpdPhotoUrl: state => state.rootUrl+'/storage/cpd/photo/',
    getAuthorizationToken: state => state.authorizationToken,
    getRootUrl: () => rootUrl,
    websiteUrl: () => websiteUrl,
    printReceiptUrl: () => printReceiptUrl,
    remitaApiKey: state => state.remitaApiKey,
    remitaPaymentUrl: state => state.remitaPaymentUrl,
    remitaMerchantId: state => state.remitaMerchantId,
    completeImageUrl: () => completeImageUrl,
    certificateImageUrl: () => certificateImageUrl,
    getportalUrl: () => portalUrl,
    paystackkey: () => paystackkey,
    paystackSubaccount: () => subaccount
};

const actions = { };

const mutations = { };

export default {
    state,
    getters,
    actions,
    mutations
}