import Vue from 'vue'
import Router from 'vue-router'
import DashboardLayout from '@/layout/DashboardLayout'
import AuthLayout from '@/layout/AuthLayout'
import store from './store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/',
      redirect: 'dashboard',
      component: DashboardLayout,
      children: [
        {
          path: '/dashboard',
          name: 'dashboard',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "demo" */ './views/Dashboard.vue')
        },
        {
          path: '/profile',
          name: 'profile',
          component: () => import(/* webpackChunkName: "demo" */ './views/UserProfile.vue')
        },
        {
          path: '/requirements-policy',
          name: 'Requirements Policy',
          component: () => import(/* webpackChunkName: "demo" */ './views/Others/PortalGuidelines.vue')
        },
        {
          path: '/change/password',
          name: 'Change Password',
          component: () => import(/* webpackChunkName: "demo" */ './views/Profile/ChangePassword.vue')
        },
        {
          path: '/applications',
          name: 'My Applications',
          component: () => import(/* webpackChunkName: "demo" */ './views/Applications/Applications.vue')
        },
        {
          path: '/application-details/:id',
          name: 'Application Details',
          component: () => import(/* webpackChunkName: "demo" */ './views/Applications/ApplicationDetails.vue')
        },
        {
          path: '/edit-application/:id',
          name: 'Edit Indexing Application',
          component: () => import(/* webpackChunkName: "demo" */ './views/Applications/EditApplication/EditApplication.vue')
        },
        {
          path: '/payments',
          name: 'My Payments',
          component: () => import(/* webpackChunkName: "demo" */ './views/Payments/Payments.vue')
        },
        {
          path: '/indexing/apply',
          name: 'Apply for Indexing Application',
          component: () => import(/* webpackChunkName: "demo" */ './views/Applications/NewApplication/NewApplication.vue')
        },
        {
          path: '/new/indexing/application',
          name: 'New Indexing Application',
          component: () => import(/* webpackChunkName: "demo" */ './views/Applications/NewApplication/NewIndexing.vue')
        },
        {
          path: '/new/indexing/generate',
          name: 'New Indexing Application Payment Page',
          component: () => import(/* webpackChunkName: "demo" */ './views/Applications/NewApplication/NewIndexingPayment/NewIndexingGenerate.vue')
        },
        {
          path: '/new/indexing/check/payment',
          name: 'New Indexing Application Payment Check Page',
          component: () => import(/* webpackChunkName: "demo" */ './views/Applications/NewApplication/NewIndexingPayment/NewIndexingRrr.vue')
        },
        {
          path: '/new/application/form',
          name: 'New Student Indexing Application Form',
          component: () => import(/* webpackChunkName: "demo" */ './views/Applications/NewApplication/NewApplicationForm/NewApplicationForm.vue')
        },
        {
          path: '/insurance/payments',
          name: 'My Insurance Payments',
          component: () => import(/* webpackChunkName: "demo" */ './views/InsurancePayments/InsurancePayments.vue')
        },





        




        
        // {
        //   path: '/pending-application/:id',
        //   name: 'Pending Application',
        //   component: () => import(/* webpackChunkName: "demo" */ './views/Applications/NewApplication/ApplicationForm.vue')
        // },
        
        // {
        //   path: '/edit-application-form',
        //   name: 'Edit Application Form',
        //   component: () => import(/* webpackChunkName: "demo" */ './views/Applications/EditApplication/EditApplicationForm.vue')
        // },
        // {
        //   path: '/visitation-payment',
        //   name: 'Visitation Payment',
        //   component: () => import(/* webpackChunkName: "demo" */ './views/Applications/Visitation/Visitation.vue')
        // },
        // {
        //   path: '/visitation-generate',
        //   name: 'Visitation Payment Page',
        //   component: () => import(/* webpackChunkName: "demo" */ './views/Applications/Visitation/VisitationGenerate.vue')
        // },
        // {
        //   path: '/visitation-preview',
        //   name: 'Visitation Preview Application',
        //   component: () => import(/* webpackChunkName: "demo" */ './views/Applications/Visitation/VisitationPreview.vue')
        // },
        // {
        //   path: '/re-accreditation',
        //   name: 'Re-Accreditation',
        //   component: () => import(/* webpackChunkName: "demo" */ './views/ReAccreditation/ReAccreditation.vue')
        // },
        // {
        //   path: '/re-accreditation-generate',
        //   name: 'Re-Accreditation Payment Page',
        //   component: () => import(/* webpackChunkName: "demo" */ './views/ReAccreditation/Application/ReAccreditationGenerate.vue')
        // },        
        // {
        //   path: '/re-accreditation-callback',
        //   name: 'Re-Accreditation Payment Callback',
        //   component: () => import(/* webpackChunkName: "demo" */ './views/ReAccreditation/Application/ReAccreditationCallback.vue')
        // },
        // {
        //   path: '/re-accreditation/check-payment',
        //   name: 'Re-Accreditation Payment Check Page',
        //   component: () => import(/* webpackChunkName: "demo" */ './views/ReAccreditation/Application/ReAccreditationRrr.vue')
        // },
        // {
        //   path: '/re-accreditation-form',
        //   name: 'Re-Accreditation Form',
        //   component: () => import(/* webpackChunkName: "demo" */ './views/ReAccreditation/Application/ReAccreditationForm.vue')
        // },
        // {
        //   path: '/re-visitation-generate',
        //   name: 'Re-Accreditation Visitation Payment Page',
        //   component: () => import(/* webpackChunkName: "demo" */ './views/ReAccreditation/Visitation/ReVisitationGenerate.vue')
        // },
        // {
        //   path: '/re-visitation-callback',
        //   name: 'Re-Accreditation Visitation Payment Callback',
        //   component: () => import(/* webpackChunkName: "demo" */ './views/ReAccreditation/Visitation/ReVisitationCallback.vue')
        // },
        // {
        //   path: '/re-visitation/check-payment',
        //   name: 'Re-Accreditation Visitation Payment Check Page',
        //   component: () => import(/* webpackChunkName: "demo" */ './views/ReAccreditation/Visitation/ReVisitationRrr.vue')
        // },
        // {
        //   path: '/certificates',
        //   name: 'My Certificates',
        //   component: () => import(/* webpackChunkName: "demo" */ './views/Letters/Letters.vue')
        // },
        // {
        //   path: '/visitation-callback',
        //   name: 'Visitation Payment Callback',
        //   component: () => import(/* webpackChunkName: "demo" */ './views/Applications/Visitation/VisitationCallback.vue')
        // },
        // {
        //   path: '/visitation/check-payment',
        //   name: 'Visitation Payment Check Page',
        //   component: () => import(/* webpackChunkName: "demo" */ './views/Applications/Visitation/VisitationRrr.vue')
        // },
        
        // {
        //   path: '/notifications',
        //   name: 'Notifications',
        //   component: () => import(/* webpackChunkName: "demo" */ './views/Notifications/Notifications.vue')
        // },
        // {
        //   path: '/notifications/:slug',
        //   name: 'Notification Details',
        //   component: () => import(/* webpackChunkName: "demo" */ './views/Notifications/SingleNotification.vue')
        // },



        {
          path: '/logout',
          name: 'logout',
          component: () => import(/* webpackChunkName: "demo" */ './views/Logout.vue')
        }
      ],
      meta: {
        requiresAuth: true
      }
    },


    {
      path: '/indexing/payment',
      name: 'Indexing Application Page',
      component: () => import(/* webpackChunkName: "demo" */ './views/Verification/Verification.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/indexing/generate',
      name: 'Indexing Application Payment Page',
      component: () => import(/* webpackChunkName: "demo" */ './views/Verification/VerificationGenerate.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/indexing/callback',
      name: 'Indexing Application Payment Callback Page',
      component: () => import(/* webpackChunkName: "demo" */ './views/Verification/VerificationCallback.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/application/check/payment',
      name: 'Indexing Application Payment Check Page',
      component: () => import(/* webpackChunkName: "demo" */ './views/Verification/VerificationRrr.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/application/form',
      name: 'Student Indexing Application Form',
      component: () => import(/* webpackChunkName: "demo" */ './views/Verification/Application/ApplicationForm.vue'),
      meta: {
        requiresAuth: true
      }
    },
    // {
    //   path: '/renewal-registration',
    //   name: 'Renewal Registration For Existing CPD Providers',
    //   component: () => import(/* webpackChunkName: "demo" */ './views/ExistingCPDs/ExistingCPDs.vue'),
    //   meta: {
    //     requiresAuth: true
    //   }
    // },


    {
      path: '/',
      redirect: 'login',
      component: AuthLayout,
      children: [
        {
          path: '/login',
          name: 'Student Login',
          component: () => import(/* webpackChunkName: "demo" */ './views/Auth/Login/Login.vue')
        },
        {
          path: '/register',
          name: 'New Student Account',
          component: () => import(/* webpackChunkName: "demo" */ './views/Auth/Register/Register.vue')
        },
        {
          path: '/password-reset',
          name: 'password reset',
          component: () => import(/* webpackChunkName: "demo" */ './views/PasswordReset.vue')
        },
        {
          path: '/update-password',
          name: 'Update Password',
          component: () => import(/* webpackChunkName: "demo" */ './views/UpdatePassword.vue')
        }
      ],
      meta: {
        requiresVisitor: true
      }
    },
    {
      path: '/requirements',
      name: 'Requirements',
      component: () => import(/* webpackChunkName: "demo" */ './views/Requirements/Requirements.vue'),
      meta: {
        requiresVisitor: true
      }
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    let saved = localStorage.getItem('time_out');// set access token
    let lockTime = localStorage.getItem('lock_time');// get lock time
    if(! lockTime) {// initiate profile lock function
      let hours = 3;
      localStorage.setItem('lock_time', new Date().getTime() + (hours * 60 * 60 * 1000));// set lock time
    }
    if (saved && (saved < new Date().getTime())) {//session time out
        localStorage.clear();
        window.location.reload();
    }
    if (! store.getters.isLoggedIn) {
      next({
        path: '/login'
      })
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.requiresVisitor)) {
    if (store.getters.isLoggedIn) {
      next({
        path: '/'
      })
    } else {
      next()
    }
  } else {
    next()
  }
});

export default router;
