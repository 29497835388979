<template>
    <base-nav class="navbar-top navbar-dark"
              id="navbar-main"
              :show-toggle-button="false"
              expand>
        <form class="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
            <div class="form-group mb-0"></div>
        </form>
        <!-- <ul class="navbar-nav align-items-center d-none d-md-flex">
            <li class="nav-item dropdown">
                <base-dropdown class="nav-link pr-0">
                    <div class="media align-items-center" slot="title">
                      <div
                        class="media-body ml-2 d-none d-lg-block"
                        v-if="student.full_name"
                      >
                        <i class="ni ni-bell-55"></i>
                        <span class="mb-0 text-sm  font-weight-bold text-capitalize">
                          Notifications
                          <badge type="white" v-if="notifications.total > 0">
                            {{ notifications.total }}
                          </badge>&nbsp;
                        </span>
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                      </div>
                    </div>

                    <template>
                        <div
                          v-if="notifications && notifications.records && notifications.records.length > 0"
                        >
                          <router-link
                            :to="'/notifications/'+notification.id"
                            class="dropdown-item"
                            v-for="notification in notifications.records"
                            :key="notification.id"
                          >
                            <i class="fa fa-angle-right"></i>
                            <span
                              :class="'notification-size ' +
                                ((notification.status == 'unread') ? 'font-weight-bold' : '')"
                            >
                              {{ truncate(notification.subject) }}
                            </span>
                          </router-link>
                        </div>
                        <div class="dropdown-divider"></div>
                        <router-link to="/notifications" class="dropdown-item">
                            <i class="ni ni-sound-wave"></i>
                            <span>View All</span>
                        </router-link>
                    </template>
                </base-dropdown>
            </li>
        </ul> -->
        <ul class="navbar-nav align-items-center d-none d-md-flex">
            <li class="nav-item dropdown">
                <base-dropdown class="nav-link pr-0">
                    <div class="media align-items-center" slot="title">
                      <span class="avatar avatar-sm rounded-circle">
                        <img
                            :alt="student.full_name"
                            :src="photo"
                            @error="replaceByDefault"
                            class="image-size"
                        >
                      </span>
                      <div
                        class="media-body ml-2 d-none d-lg-block"
                        v-if="student && student.full_name"
                      >
                        <span class="mb-0 text-sm  font-weight-bold text-capitalize">
                          {{ student.full_name }}
                        </span>
                        <i class="fa fa-caret-down" aria-hidden="true"></i>
                      </div>
                    </div>

                    <template>
                        <div class=" dropdown-header noti-title" v-if="student">
                            <h6 class="text-overflow m-0">
                              Welcome, {{ truncate(student.full_name) }}
                            </h6>
                        </div>

                        <div>
                          <router-link to="/profile"
                            class="dropdown-item"
                          >
                              <i class="ni ni-single-02"></i>
                              <span>My Account</span>
                          </router-link>
                          <router-link to="/change/password"
                            class="dropdown-item"
                          >
                              <i class="ni ni-settings-gear-65"></i>
                              <span>Change Password</span>
                          </router-link>
                          <!-- <router-link to="/notifications"
                            class="dropdown-item"
                          >
                              <i class="ni ni-bell-55"></i>
                              <span>Notifications</span>
                          </router-link> -->
                        </div>
                        <div class="dropdown-divider"></div>
                        <router-link to="/logout" class="dropdown-item">
                            <i class="ni ni-user-run"></i>
                            <span>Logout</span>
                        </router-link>
                    </template>
                </base-dropdown>
            </li>
        </ul>
    </base-nav>
</template>
<script>
  // get user's details from store
  import { mapGetters, mapActions } from 'vuex';

  export default {
    data() {
      return {
        activeNotifications: false,
        showMenu: false,
        searchQuery: ''
      };
    },
    computed: {
      ...mapGetters(['student', 'blankImageUrl', 'notifications']),
      photo() {// get MD Passport
        return this.student && this.student.photo_url ? this.student.photo_url : this.blankImageUrl;
      }
    },
    methods: {
      truncate: function (text) {
        if (text && text.length > 20) {
          return text.substring(0, 30) + '...'
        } else {
          return text
        }
      },
      toggleSidebar() {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
      },
      hideSidebar() {
        this.$sidebar.displaySidebar(false);
      },
      toggleMenu() {
        this.showMenu = !this.showMenu;
      },
      ...mapActions(['fetchStudentProfile', 'getCpdNotifications']),
      replaceByDefault(e) {
        return e.target.src = this.blankImageUrl;
      }
    },
    created() {
      this.fetchStudentProfile();
    },
    mounted() {
      // this.getCpdNotifications(5);
    }
  };
</script>

<style scoped>
  .image-size {
    width: 40px;
    height: 40px;
  }
  .notification-size {
    font-size: 12px;
  }
</style>
